import axios from "axios";
import extractVidcloud from "backend/extractors/vidcloud";
import { load } from "cheerio";
import * as retryAxios from "retry-axios";
import { MediaType } from "types";
import { compareTitle } from "utils/helper";
import { PATH_API } from "../../../constants";
import { IMediaSource, IParamsMediaSource } from "../types";
import { FlixhqType, ISearchResult } from "./types";

export const FLIXHQ_BASE_URL = "https://flixhq.to";

const axiosRetry = axios.create();
axiosRetry.defaults.raxConfig = {
  instance: axiosRetry,
};
retryAxios.attach(axiosRetry);

export const getMediaSourcesFlixhq = async ({
  title,
  extraData,
}: IParamsMediaSource): Promise<IMediaSource> => {
  try {
    const mediaType = extraData.mediaType === MediaType.MOVIE ? "Movie" : "TV Series";
    const { data } = await axiosRetry.post(
      PATH_API.BYPASS_CORS_WORKERS(`${FLIXHQ_BASE_URL}/ajax/search`),
      `keyword=${title.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "-")}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    const $ = load(data);
    let searchResults: ISearchResult[] = [];
    $("a.nav-item").each((_, el) => {
      const releaseDate = $(el).find("div.film-infor span:first-of-type").text();
      searchResults.push({
        id: $(el).attr("href")?.slice(1)!,
        title: $(el).find("h3.film-name").text()?.trim()!,
        url: `${FLIXHQ_BASE_URL}${$(el).attr("href")}`,
        releaseDate: isNaN(parseInt(releaseDate)) ? undefined : releaseDate,
        seasons: releaseDate.includes("SS") ? parseInt(releaseDate.split("SS")[1]) : undefined,
        type:
          $(el).find("div.film-infor > span").last().text() === "Movie"
            ? FlixhqType.MOVIE
            : FlixhqType.TVSERIES,
      });
    });
    //remove results that dont match the media type
    searchResults = searchResults.filter((result: ISearchResult) => {
      if (mediaType === FlixhqType.MOVIE) return (result.type as string) === FlixhqType.MOVIE;
      else if (mediaType === FlixhqType.TVSERIES)
        return (result.type as string) === FlixhqType.TVSERIES;
      else return result;
    });
    searchResults = searchResults.filter((result: ISearchResult) =>
      compareTitle(result.title, title)
    );
    // if extraData contains a year, filter out the results that don't match the year
    if (extraData.year && mediaType === FlixhqType.MOVIE) {
      searchResults = searchResults.filter((result: ISearchResult) => {
        return Number(result.releaseDate) === extraData.year;
      });
    }
    // check if the result contains the total number of seasons and compare it to the extraData by 1 up or down and make sure that its a number
    if (extraData.totalSeasons && mediaType === FlixhqType.TVSERIES) {
      searchResults = searchResults.filter((result: ISearchResult) => {
        if (!result.seasons) return false;
        const totalSeasons = (result.seasons as number) || 0;
        const extraDataSeasons = (totalSeasons as number) || 0;
        return (
          totalSeasons === extraDataSeasons ||
          totalSeasons === extraDataSeasons + 1 ||
          totalSeasons === extraDataSeasons - 1
        );
      });
    }
    const foundResult = searchResults?.[0];
    let episodes: { id: string; ep: number }[] = [];
    const ajaxReqUrl = (id: string, type: string, isSeasons: boolean = false) =>
      PATH_API.BYPASS_CORS_WORKERS(
        `${FLIXHQ_BASE_URL}/ajax/${type === "movie" ? type : `v2/${type}`}/${
          isSeasons ? "seasons" : "episodes"
        }/${id}`
      );
    if (mediaType === FlixhqType.TVSERIES) {
      let seasonsIds: string[] = [];
      const { data: dataTV } = await axiosRetry.get(
        ajaxReqUrl(foundResult.url.split("-").pop()!, "tv", true),
        {
          timeout: 7000,
        }
      );
      const $$ = load(dataTV);
      seasonsIds = $$(".dropdown-menu > a")
        .map((_, el) => $(el).attr("data-id"))
        .get();
      const foundSeasonId = seasonsIds[Number(extraData.seasonId || 1) - 1];
      const { data } = await axiosRetry.get(ajaxReqUrl(foundSeasonId, "season"), {
        timeout: 7000,
      });
      const $$$ = load(data);
      $$$(".nav > li")
        .each((_, el) => {
          const episode = {
            id: $$$(el).find("a").attr("id")!.split("-")[1],
            ep: parseInt($$$(el).find("a").attr("title")!.split(":")[0].slice(3).trim()),
          };
          episodes?.push(episode);
        })
        .get();
    }
    const ajaxUrlServers =
      mediaType === FlixhqType.MOVIE
        ? `${FLIXHQ_BASE_URL}/ajax/movie/episodes/${foundResult.url.split("-").pop()}`
        : `${FLIXHQ_BASE_URL}/ajax/v2/episode/servers/${
            episodes.find((ep) => ep.ep === Number(extraData.episodeId || 1))?.id
          }`;
    const { data: dataServers } = await axiosRetry.get(
      PATH_API.BYPASS_CORS_WORKERS(ajaxUrlServers),
      {
        timeout: 7000,
      }
    );
    const $$$$ = load(dataServers);
    const upcloudServerId = $$$$(
      `.nav a[title='${mediaType === FlixhqType.MOVIE ? "UpCloud" : "Server UpCloud"}']`
    ).attr(mediaType === FlixhqType.MOVIE ? "data-linkid" : "data-id")!;
    if (!upcloudServerId) throw new Error(`Server upcloud not found`);
    const { data: dataUpcloud } = await axiosRetry.get(
      PATH_API.BYPASS_CORS_WORKERS(`${FLIXHQ_BASE_URL}/ajax/get_link/${upcloudServerId}`)
    );
    const mediaSources = await extractVidcloud(dataUpcloud.link);
    return mediaSources;
  } catch (error) {
    console.log("error: ", error);
    throw new Error("Unable to fetch movie stream");
  }
};
