export const PATH_API = {
  BYPASS_CORS_WORKERS: (url: string) => {
    return `https://testarembed.moovtuga.workers.dev/?destination=${encodeURIComponent(
      url
    )}`;
  },
  API_OPENSUBTITLES: "https://legenda-api.moovtuga.workers.dev/?destination=",
  BYPASS_CORS_CORIO: (url: string) => {
    return `https://corsproxy.io/?${encodeURIComponent(url)}`;
  },
};
